
import accountAPI from '@/api/account';
import { debounce } from 'lodash';
import { AccountTypes, ExternalRoles } from '@/constants/account';

export default {
  name: 'AccountSelector',
  props: {
    value: {
      type: Object | String | Array | null,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    client: {
      type: Boolean,
      required: false,
      default: false,
    },
    employee: {
      type: Boolean,
      required: false,
      default: false,
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    groups: {
      type: Array | String,
      required: false,
    },
    excludeGroups: {
      type: Array | String,
      required: false,
    },
    departments: {
      type: Array | String,
      required: false,
    },
    includeAccounts: {
      type: Array | String,
      required: false,
    },
    labelSize: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    roles: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      AccountTypes,
      ExternalRoles,
      loading: false,
      optionsList: [],
    };
  },

  computed: {
    selected: {
      get: function () {
        return this.value;
      },
      set: function (newVal) {
        this.$emit('input', newVal);
      },
    },

    displayLabel() {
      if (this.label) {
        return this.label;
      }

      if (this.external && !this.client && !this.employee) {
        return 'Select introducer';
      } else if (this.employee && !this.client && !this.external) {
        return 'Select employee';
      } else if (this.client && !this.employee && !this.external) {
        return 'Select client';
      } else {
        return 'Select accounts';
      }
    },

    displayPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.external && !this.client && !this.employee) {
        return `Start typing an introducer's name to search.`;
      } else if (this.employee && !this.client && !this.external) {
        return `Start typing an employee's name to search.`;
      } else if (this.client && !this.employee && !this.external) {
        return `Start typing a client's name to search.`;
      } else {
        return `Start typing a name to search.`;
      }
    },
  },

  created() {
    if (!this.client) {
      this.grabAccounts();
    }
  },

  methods: {
    async grabData(val) {
      if (this.client) {
        await this.grabClients(val);
      }
    },

    grabClients: debounce(async function (val) {
      let filterType = '';
      if (this.client) filterType = `${filterType},${AccountTypes.Client}`;
      if (this.employee) filterType = `${filterType},${AccountTypes.Employee}`;
      if (this.external) filterType = `${filterType},${AccountTypes.External}`;

      filterType = filterType.slice(1);

      if (val.length >= 3) {
        this.loading = true;

        const params = {
          ...this.getDefaultParams(),
          'filter[name]': val,
          'filter[type]': filterType,
          include: 'primary_email',
        };

        const rsp = await accountAPI.getAccounts({ params });
        this.optionsList = [...rsp.data.data];
        this.loading = false;
      } else if (!val.length) {
        this.optionsList = [];
      }
    }, 500),

    async grabAccounts() {
      let filterType = '';
      if (this.employee) filterType = `${filterType},${AccountTypes.Employee}`;
      if (this.external) filterType = `${filterType},${AccountTypes.External}`;

      filterType = filterType.slice(1);

      const params = {
        ...this.getDefaultParams(),
        'filter[type]': filterType,
        'filter[role]': this.external && !this.employee ? ExternalRoles.Introducer : null,
      };

      const rsp = await accountAPI.getAccounts({ params });
      this.optionsList = rsp.data.data;
      this.loading = false;
    },

    getDefaultParams() {
      let params = {
        per_page: 200,
      };

      if (this.groups) {
        params['filter[groups]'] = Array.isArray(this.groups) ? this.groups.join(',') : this.groups;
      }
      if (this.excludeGroups) {
        params['filter[exclude_groups]'] = Array.isArray(this.excludeGroups)
          ? this.excludeGroups.join(',')
          : this.groups;
      }

      if (this.departments) {
        params['filter[departments]'] = Array.isArray(this.departments) ? this.departments.join(',') : this.departments;
      }

      if (this.includeAccounts) {
        params['filter[include]'] = Array.isArray(this.includeAccounts)
          ? this.includeAccounts.join(',')
          : this.includeAccounts;
      }

      if (this.roles.length) {  
        params['filter[role]'] = this.roles.join(',');  
      }  

      return params;
    },
  },
};
