
import hasCustomFields from '@/mixins/hasCustomFields';
import checkForErrors from '@/helpers/checkForErrors';
import { mapActions, mapGetters, mapState } from 'vuex';
import tasksAPI from '@/api/tasks';
import { capitalCase } from 'change-case';

export default {
  name: 'CreateTask',
  mixins: [hasCustomFields],
  props: {
    task: {
      type: Object,
      required: false,
      default: () => {},
    },
    addTaskFromStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAddingFromTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    caseForTask: {
      type: Object,
      required: false,
      default: () => {},
    },
    planForTask: {
      type: Object,
      required: false,
      default: () => {},
    },
    creatingFromTaskable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      datePickerColour: '#53DBB8',
      errors: {},
      selected: [],
      isAddingSubtask: false,
      relatedTasks: [],
      presetStatus: null,
      priorities: [
        {
          label: 'Highest',
          value: 'highest',
        },
        {
          label: 'High',
          value: 'high',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Low',
          value: 'low',
        },
      ],
      taskableTypes: [
        {
          label: 'Client',
          value: 'client',
        },
        {
          label: 'Case',
          value: 'case',
        },
        {
          label: 'Plan',
          value: 'plan',
        },
      ],
      taskOptions: [
        {
          label: 'New subtask',
          value: false,
        },
        {
          label: 'Add existing subtask',
          value: true,
        },
      ],
      newTask: {},
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('client', ['client']),
    ...mapState('tasks', ['taskStatuses']),
    ...mapGetters('customFields', ['getCustomFieldByModelType']),

    taskableType() {
      if (this.task && this.task.taskable_type) {
        return capitalCase(this.task.taskable_type);
      } else {
        if (this.creatingFromTaskable) {
          if (this.caseForTask) {
            return 'Case';
          } else if (this.planForTask) {
            return 'Plan';
          } else {
            return 'Client';
          }
        }
      }
    },
  },

  created() {
    this.resetTask();
  },

  mounted() {
    this.getCustomFields();
    if (this.taskStatuses.length === 0 && this.$route.name !== 'dashboard-my-tasks') {
      this.fetchTaskStatuses();
    }
    if (this.presetStatus) {
      this.newTask.task_status_uuid = this.presetStatus;
    }
  },

  methods: {
    ...mapActions('tasks', ['fetchTaskStatuses']),
    getCustomFields() {
      this.customFields = this.getCustomFieldByModelType('task');
    },

    mapTaskProperties(e) {
      let task = {};

      const properties = [
        'name',
        'description',
        'due_at',
        'priority',
        'assigned_to_uuids',
        'taskable_type',
        'taskable_uuid',
        'task_status_uuid',
        'custom_fields',
      ];

      properties.forEach((prop) => {
        if (prop === 'taskable_type' || prop === 'taskable_uuid') {
          if (this.creatingFromTaskable) {
            if (this.caseForTask) {
              task.taskable_type = 'case';
              task.taskable_uuid = this.caseForTask?.uuid;
            } else if (this.planForTask) {
              task.taskable_type = 'plan';
              task.taskable_uuid = this.planForTask?.uuid;
            } else {
              task.taskable_type = 'client';
              task.taskable_uuid = this.client.uuid;
            }
          } else {
            task.taskable_type = e.taskable_type;
            if (task.taskable_type === 'client') {
              task.taskable_uuid = e.client.uuid;
            } else {
              task.taskable_uuid = e.taskable_uuid;
            }
          }
        } else if (prop === 'assigned_to_uuids' && this.newTask.assigned_to_uuids.length > 0) {
          task.assigned_to_uuids = this.newTask.assigned_to_uuids.map((obj) => obj.uuid);
        } else if (e[prop]) {
          task[prop] = e[prop];
        }
      });

      this.$nuxt.$helpers.removeNullPropertiesInObject(task);

      return task;
    },

    async saveItem(e) {
      let task = this.mapTaskProperties(e);
      if (this.$route.params.tasksid) {
        task.parent_task_uuid = this.$route.params.tasksid;
      }
      try {
        await this.$store.dispatch('tasks/createTask', task);
        this.$nuxt.$emit('refreshTasks');
        this.$nuxt.$emit('refreshTaskDetails');
        this.close();
      } catch (err) {
        if (err.response.status === 422) {
          this.errors = err.response.data.errors;
        }
        checkForErrors.process(err, this.$refs.formErrorPanel);
      }
    },

    async saveAsSubtask() {
      if (this.selected.length === 0) {
        return;
      }

      try {
        for (let i = 0; i < this.selected.length; i++) {
          await this.$store.dispatch('tasks/updateTask', {
            task_uuid: this.selected[i].uuid,
            data: { parent_task_uuid: this.task.uuid },
          });
          this.$nuxt.$emit('refreshTaskDetails');
        }
      } catch (err) {
        checkForErrors.process(err, this.$refs.formErrorPanel);
      }

      this.close();
    },

    resetTask() {
      this.newTask = {
        name: null,
        description: '',
        due_at: null,
        task_status_uuid: this.presetStatus || null,
        taskable_uuid: null,
        taskable_type: null,
        parent_task_uuid: null,
        assigned_to_uuids: [],
        priority: null,
        tags: [],
        custom_fields: {},
      };
    },

    async loadRelatedTasks() {
      let taskableType = this.creatingFromTaskable ? this.task.taskable_type : 'client';
      let taskableUuid = null;
      switch (taskableType) {
        case 'client':
          taskableUuid = this.client.uuid;
          break;
        case 'case':
          taskableUuid = this.caseForTask.uuid;
          break;
        case 'plan':
          taskableUuid = this.planForTask.uuid;
          break;
      }
      const params = {
        'filter[open_tasks]': true,
        'filter[taskable_uuid]': taskableUuid,
        'filter[taskable_type]': taskableType,
        sort: 'due_at',
      };
      try {
        let rsp = await tasksAPI.getAllTasks({
          params,
        });
        this.relatedTasks = rsp.data.data;
      } catch (err) {
        checkForErrors.process(err, this.$refs.formErrorPanel);
      }

      this.relatedTasks = this.relatedTasks.filter(
        (task) => task.uuid !== this.task.uuid && task.parent_task_uuid !== this.task.uuid
      );
    },

    async open() {
      this.$refs.modal.open();
      if (
        this.user.current_account &&
        !this.newTask.assigned_to_uuids.some((item) => item.uuid === this.user.current_account.uuid)
      ) {
        this.newTask.assigned_to_uuids.push(this.user.current_account);
      }
      if (this.creatingFromTaskable && this.isAddingFromTask) {
        await this.loadRelatedTasks();
      }
    },

    close() {
      this.resetTask();
      this.$refs.modal.close();
    },
  },

  watch: {
    name(new_val) {
      if (new_val !== null) {
        this.newTask.name = new_val;
      }
    },
    presetStatus(new_val) {
      if (new_val !== null) {
        this.newTask.task_status_uuid = new_val;
      }
    },
    customFields(new_val) {
      this.newTask = this.buildDataBlob(this.newTask);
    },
  },
};
