export default ({ app }, inject) => {
  inject('api', {
    get host() {
      return app.$config.laravelURL + '/api/v1';
    },
    get hostV2() {
      return app.$config.laravelURL + '/api/v2';
    },
    get baseURL() {
      return app.$config.laravelURL.replace('https://', '');
    },
    get laravelURL() {
      return app.$config.laravelURL;
    },
  });
};
