import { sentenceCase } from 'change-case';
import { Crisp } from 'crisp-sdk-web';
import DOMPurify from 'dompurify';

export default ({ app }, inject) => {
  inject('helpers', {
    poundsToPennies(amount) {
      if (amount == null) {
        return null;
      }

      if (typeof amount !== 'string' && typeof amount !== 'number') {
        throw new Error('Amount passed must be of type String or Number.');
      }
      return Math.round(100 * parseFloat(typeof amount === 'string' ? amount.replace(/[£,]/g, '') : amount));
    },

    penniesToPounds(amount) {
      if (amount == null) {
        return null;
      }

      if (typeof amount !== 'string' && typeof amount !== 'number') {
        throw new Error('Amount passed must be of type String or Number.');
      }

      return (amount / 100).toFixed(2);
    },

    pounds(amount) {
      if (amount == null) {
        return null;
      }

      if (typeof amount !== 'string' && typeof amount !== 'number') {
        throw new Error('Amount passed must be of type String or Number.');
      }

      return amount.toFixed(2);
    },

    formatCurrency(amount, currency = 'GBP', locale = 'en-GB', currencySign = 'standard') {
      let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencySign: currencySign,
      });
      amount = this.penniesToPounds(amount);
      return formatter.format(amount);
    },

    formatPoundCurrency(amount, currency = 'GBP', locale = 'en-GB', currencySign = 'standard') {
      let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencySign: currencySign,
      });
      amount = this.pounds(amount);
      return formatter.format(amount);
    },

    nicelyFormattedDate(raw) {
      return app.$dayjs(raw).format('Do MMM YYYY');
    },

    basicDate(value) {
      return app.$dayjs(value).format('DD-MM-YYYY');
    },
    basicDateWithSlashes(value) {
      return app.$dayjs(value).format('DD/MM/YYYY');
    },

    dateToMonthAndDay(value) {
      return app.$dayjs(value).format('Do MMM @ HH:mm');
    },

    formatHumanDate(value) {
      return app.$dayjs(value).format('Do MMM YYYY');
    },

    nicelyFormattedWithMonth(value) {
      return app.$dayjs(value).format('Do MMMM YYYY');
    },

    formatHumanDateTime(value) {
      return app.$dayjs(value).format('Do MMM YYYY HH:mm:ss');
    },

    formatDateFromNow(value) {
      return app.$dayjs(value).fromNow();
    },

    formatHumanDateTimeAndMinutes(value) {
      if (app.$dayjs(value).isToday()) {
        return 'Today at ' + app.$dayjs(value).format('HH:mm');
      } else {
        return app.$dayjs(value).format('Do MMM YYYY HH:mm');
      }
    },

    formatDate(value) {
      if (value === null) {
        return null;
      }

      let dayjs = app.$dayjs(value);
      return dayjs.format('YYYY-MM-DD');
    },

    removeNullPropertiesInObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] == null || obj[key] === '' || obj[key] === "" || (Array.isArray(obj[key]) && obj[key].length === 0)) {
          delete obj[key];
        }
      });

      return obj;
    },

    formatDateForCalendar(value = null) {
      if (value === null) {
        return app.$dayjs().format('YYYY-MM-DD');
      }

      let dayjs = app.$dayjs(value);
      return dayjs.format('YYYY-MM-DD');
    },

    getTimeZoneFormatter(timeZone = 'Europe/London') {
      const formatterOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };

      // it'll default to the browser's timezone if not specified
      if (timeZone && timeZone !== 'browser') {
        formatterOptions.timeZone = timeZone;
      }

      return new Intl.DateTimeFormat('en-GB', formatterOptions);
    },

    convertIntlPartsToDateObj(partsArray) {
      const partsObject = partsArray.reduce((acc, part) => {
        if (part.type !== 'literal') {
          acc[part.type] = part.value;
        }
        return acc;
      }, {});

      const date = `${partsObject.year}-${partsObject.month}-${partsObject.day}`;
      const time = `${partsObject.hour}:${partsObject.minute}:${partsObject.second}`;

      return {
        parts: partsObject,
        date,
        time,
        dateTime: `${date} ${time}`,
      };
    },

    convertToTimeZoneDateTimeObj(dateTime, timeZone = 'browser') {
      if (!(dateTime instanceof Date)) {
        const dateFormat = this.getDateTimeFormat(dateTime);

        if (!dateFormat || dateTime === 'now') {
          dateTime = new Date();
        } else if (dateFormat === 'date') {
          dateTime = new Date(`${dateTime} 00:00:00`);
        }
      }

      const tzDateFormatter = this.getTimeZoneFormatter(timeZone);
      const parts = tzDateFormatter.formatToParts(typeof dateTime === 'string' ? new Date(dateTime) : dateTime);
      const dateObj = this.convertIntlPartsToDateObj(parts);
      return dateObj;
    },

    convertToLondonDateTime(date) {
      if (!date) return;

      return this.convertToTimeZoneDateTimeObj(date, 'Europe/London').dateTime;
    },

    getDateTimeFormat(inputString) {
      if (typeof inputString !== 'string') return null;

      const datetimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      const timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[+-]\d{2}:\d{2})$/;

      if (iso8601Regex.test(inputString)) {
        return 'iso8601';
      } else if (datetimeRegex.test(inputString)) {
        return 'datetime';
      } else if (dateRegex.test(inputString)) {
        return 'date';
      } else if (timeRegex.test(inputString)) {
        return 'time';
      } else {
        return null;
      }
    },

    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      let hDisplay = h > 0 ? h + (h === 1 ? 'h, ' : 'h ') : '';
      let mDisplay = m > 0 ? m + (m == 1 ? 'm, ' : 'm ') : '';
      let sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : '';
      return hDisplay + mDisplay + sDisplay;
    },

    getCountryFlag(countryCode) {
      const flagOffset = 127397;
      return String.fromCodePoint(...[...countryCode].map((char) => char.charCodeAt(0) + flagOffset));
    },

    sentencify(text) {
      return sentenceCase(text ?? '');
    },

    cleanFilters(filters, keysToExclude = []) {
      const modifiedFilters = Object.keys(filters).reduce((acc, key) => {
        const modifiedKey = key.replace(/^filter\[(.+)\]$/, '$1');
        acc[modifiedKey] = filters[key];
        return acc;
      }, {});
      return _.pickBy(modifiedFilters, (value, key) => {
        return (
          !keysToExclude.includes(key) &&
          !(_.isNull(value) || _.isUndefined(value) || value === '' || (_.isArray(value) && _.isEmpty(value)))
        );
      });
    },

    openHelpArticle() {
      Crisp.configure('3c92c050-f137-4c5c-991f-f3fa2d562f2a');
      Crisp.chat.openHelpdeskArticle('en', 'pjbhr4');
    },

    displayYesNo(value) {
      return value ? 'Yes' : 'No';
    },

    addressToGoogleMapsLink(address) {
      if (address) {
        return (
          'https://www.google.com/maps/dir//' +
          (address.address_1 ? address?.address_1.replace(' ', '%20') : '') +
          '%20' +
          (address.address_2 ? address?.address_2.replace(' ', '%20') : '') +
          '%20' +
          (address.address_3 ? address?.address_3.replace(' ', '%20') : '') +
          '%20' +
          (address.postcode ? address?.postcode.replace(' ', '%20') : '')
        );
      }
    },

    sortBy(key, array, copy = true) {
      if (copy) {
        array = structuredClone(array);
      }

      return array.sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
    },
    sanitizeHtml(html, options = {}) {
      return DOMPurify.sanitize(html, options);
    },
    sanitizeHtmlAndStyling(html, options = {}) {
      const defaults = {
        ...options,
        FORBID_ATTR: ['nowrap', 'style'],
      };
      return this.sanitizeHtml(html, defaults);
    },

    getUniqueErrorMessages(errors, forErrorBlock = true) {
      if (!errors) {
        return [];
      }

      const flattenedErrors = Object.values(errors).flat();
      const uniqueErrors = Array.from(new Set(flattenedErrors));

      return forErrorBlock ? uniqueErrors.map((msg) => [msg]) : uniqueErrors;
    },
  });
};
